import { Resource as ResourceType } from '../types';

export const proposal: ResourceType = {
  name: 'proposals',
  label: 'resource.proposals.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.proposals.field.id.label',
      type: 'text',
    },
    {
      key: 'guid',
      label: 'resource.proposals.field.guid.label',
      type: 'text',
    },
    {
      key: 'status',
      label: 'resource.proposals.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'draft',
          name: 'resource.proposals.field.status.choices.draft.label',
        },
        {
          id: 'review_admin',
          name: 'resource.proposals.field.status.choices.review_admin.label',
        },
        {
          id: 'review_client',
          name: 'resource.proposals.field.status.choices.review_client.label',
        },
        {
          id: 'request_for_update',
          name: 'resource.proposals.field.status.choices.request_for_update.label',
        },
        {
          id: 'refused',
          name: 'resource.proposals.field.status.choices.refused.label',
        },
        {
          id: 'accepted',
          name: 'resource.proposals.field.status.choices.accepted.label',
        },
        {
          id: 'archived',
          name: 'resource.proposals.field.status.choices.archived.label',
        },
      ],
    },
    {
      key: 'mission_id',
      label: 'resource.proposals.field.mission_id.label',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'candidacy_id',
      label: 'resource.proposals.field.candidacy_id.label',
      type: 'reference',
      reference: 'candidacies',
    },
    {
      key: 'candidacy_owner_id',
      label: 'resource.proposals.field.candidacy_owner_name.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'created_at',
      label: 'resource.proposals.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.proposals.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'quotation_id',
      label: 'resource.charges.field.quotation_id.label',
      type: 'reference',
      reference: 'quotations',
    },
    {
      key: 'latest_expert_pdfs',
      label: 'resource.invoices.field.latest_expert_pdfs.label',
      type: 'buttons',
    },
    {
      key: 'latest_client_pdfs',
      label: 'resource.invoices.field.latest_client_pdfs.label',
      type: 'buttons',
    },
    {
      key: 'fo_link',
      label: 'resource.proposals.field.fo_link.label',
      type: 'frontOfficeLink',
      externalLink: {
        source: 'id',
        path: 'proposals',
      },
    },
    {
      key: 'details',
      label: 'resource.proposals.field.details.label',
      type: 'wysiwyg',
    },
    {
      key: 'deliverables',
      label: 'resource.proposals.field.deliverables.label',
      type: 'wysiwyg',
    },
    {
      key: 'goals',
      label: 'resource.proposals.field.goals.label',
      type: 'wysiwyg',
    },
    {
      key: 'comment',
      label: 'resource.proposals.field.comment.label',
      type: 'wysiwyg',
    },
    {
      key: 'attachments',
      label: 'resource.proposals.field.attachments.label',
      type: 'buttons',
    },
    {
      key: 'accepted_at',
      label: 'resource.proposals.field.accepted_at.label',
      type: 'date',
    },
    {
      key: 'company_legal_information_id',
      label: 'resource.proposals.field.company_legal_information_id.label',
      type: 'reference',
      reference: 'legal_informations',
    },
    {
      key: 'discount',
      label: 'resource.proposals.field.discount.label',
      type: 'number',
    },
    {
      key: 'payment_period',
      label: 'resource.contract_milestones.field.payment_period.label',
      type: 'select',
      choices: [
        {
          id: 'forty_five_days',
          name: 'resource.contract_milestones.field.payment_period.choices.forty_five_days_x.label',
        },
        {
          id: 'thirty_days',
          name: 'resource.contract_milestones.field.payment_period.choices.thirty_days_x.label',
        },
        {
          id: 'fifteen_days',
          name: 'resource.contract_milestones.field.payment_period.choices.fifteen_days_x.label',
        },
        {
          id: 'seventy_two_hours',
          name: 'resource.contract_milestones.field.payment_period.choices.seventy_two_hours_x.label',
        },
      ],
    },
    {
      key: 'mission_owner_id',
      label: 'resource.proposals.field.mission_owner_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'mission_company_id',
      label: 'resource.proposals.field.mission_company_name.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'quotation',
      type: 'object',
      shape: [
        {
          key: 'kind',
          type: 'select',
          label: 'resource.quotations.field.kind.label',
          choices: [
            {
              id: 'technical_assistance',
              name: 'resource.quotations.field.kind.choices.technical_assistance.label',
            },
            {
              id: 'forfeit',
              name: 'resource.quotations.field.kind.choices.forfeit.label',
            },
          ],
        },
        {
          key: 'begin_date',
          label: 'resource.quotations.field.begin_at.label',
          type: 'date',
        },
        {
          key: 'end_date',
          label: 'resource.quotations.field.end_at.label',
          type: 'date',
        },
        {
          key: 'vat',
          label: 'resource.quotations.field.vat.label',
          type: 'number',
        },
        {
          key: 'admin_vat',
          label: 'resource.quotations.field.admin_vat.label',
          type: 'number',
        },
        {
          key: 'cost_per_unit',
          label: 'resource.quotations.field.cost_per_unit.label',
          type: 'number',
        },
        {
          key: 'admin_cost_per_unit',
          label: 'resource.quotations.field.admin_cost_per_unit.label',
          type: 'number',
        },
        {
          key: 'quantity',
          label: 'resource.quotations.field.work_days.label',
          type: 'number',
        },
        {
          key: 'unit',
          label: 'resource.charges.field.unit.label',
          type: 'select',
          choices: [
            {
              id: 'units',
              name: 'resource.charges.field.unit.choices.units.label',
            },
            {
              id: 'days',
              name: 'resource.charges.field.unit.choices.days.label',
            },
            {
              id: 'work_units',
              name: 'resource.charges.field.unit.choices.work_unit.label',
            },
          ],
        },
        {
          key: 'total_duty',
          label: 'resource.quotations.field.total_duty.label',
          type: 'number',
        },
        {
          key: 'total_ttc',
          label: 'resource.quotations.field.total_ttc.label',
          type: 'number',
        },
        {
          key: 'admin_total_duty',
          label: 'resource.quotations.field.admin_total_duty.label',
          type: 'number',
        },
        {
          key: 'admin_total_ttc',
          label: 'resource.quotations.field.admin_total_ttc.label',
          type: 'number',
        },
        {
          key: 'total_duty_with_charges',
          label: 'resource.quotations.field.total_duty_with_charges.label',
          type: 'number',
        },
        {
          key: 'admin_total_duty_with_charges',
          label:
            'resource.quotations.field.admin_total_duty_with_charges.label',
          type: 'number',
        },
        {
          key: 'total_ttc_with_charges',
          label: 'resource.quotations.field.total_ttc_with_charges.label',
          type: 'number',
        },
        {
          key: 'admin_total_ttc_with_charges',
          label: 'resource.quotations.field.admin_total_ttc_with_charges.label',
          type: 'number',
        },
      ],
    },
    {
      key: 'quotation_without_charges',
      type: 'boolean',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.proposals.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
      ],
      filters: [
        {
          source: 'mission_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            optionText: 'name',
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          name: 'mission_company_id',
          type: 'reference',
          reference: 'companies',
          label: 'resource.proposals.field.mission_company_name.label',
          filter: { user_kind: 'client' },
          child: {
            type: 'autocomplete',
            optionText: 'name',
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          name: 'candidacy_company_id',
          type: 'reference',
          reference: 'companies',
          label: 'resource.proposals.field.candidacy_company_name.label',
          filter: { user_kind: 'expert' },
          child: {
            type: 'autocomplete',
            optionText: 'name',
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
      ],
      fields: [
        {
          source: 'guid',
        },
        {
          source: 'mission_id',
          sortable: false,
          child: {
            source: 'missions.name',
          },
        },
        {
          source: 'candidacy_id',
          sortable: false,
          child: {
            source: 'candidacies.name',
          },
        },
        {
          source: 'status',
        },
        {
          source: 'latest_expert_pdfs',
          sortable: false,
          extensions: [
            {
              key: 'multipleLangFiles',
            },
          ],
        },
        {
          source: 'latest_client_pdfs',
          sortable: false,
          extensions: [
            {
              key: 'multipleLangFiles',
            },
          ],
        },
        {
          source: 'fo_link',
          sortable: false,
          icon: 'Description',
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      actions: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.proposals.bulk_action_buttons.validate.label',
          values: { validated: true },
          extraUrl: '/',
          disabled: [{ property: 'status', op: 'neq', value: 'review_admin' }],
        },
        {
          name: 'view_proposal_as_client',
          type: 'update',
          label:
            'resource.proposals.bulk_action_buttons.view_proposal_as_client.label',
          values: {},
          extraUrl: '/sign_in_as',
          extensions: [{ key: 'redirect_sign_in_as' }],
          icon: undefined,
          resourceId: 'mission_owner_id',
          resourceName: 'users',
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.proposals.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.proposals.section.information.title',
                layout: [
                  ['details'],
                  ['deliverables'],
                  ['goals'],
                  ['comment'],
                  ['discount', 'payment_period'],
                  ['attachments'],
                ],
                inputs: {
                  details: {
                    validators: [
                      { key: 'required' },
                      {
                        key: 'maxLengthHTML',
                        value: 10000,
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['refused', 'accepted', 'archived'],
                        },
                      ],
                    },
                  },
                  deliverables: {
                    validators: [
                      {
                        key: 'maxLengthHTML',
                        value: 10000,
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['refused', 'accepted', 'archived'],
                        },
                      ],
                    },
                  },
                  goals: {
                    validators: [
                      {
                        key: 'maxLengthHTML',
                        value: 10000,
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['refused', 'accepted', 'archived'],
                        },
                      ],
                    },
                  },
                  comment: {
                    validators: [
                      {
                        key: 'maxLengthHTML',
                        value: 10000,
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['refused', 'accepted', 'archived'],
                        },
                      ],
                    },
                  },
                  discount: {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      {
                        key: 'required',
                      },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                      {
                        key: 'maxValue',
                        value: 100,
                      },
                    ],
                    min: 0,
                    max: 100,
                    step: 0.01,
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['refused', 'accepted', 'archived'],
                        },
                      ],
                    },
                  },
                  payment_period: {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      disabled: [
                        {
                          property: 'status',
                          op: 'includes',
                          value: ['refused', 'accepted', 'archived'],
                        },
                      ],
                    },
                  },
                },
                fields: {
                  attachments: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            key: 'information',
            title: 'resource.quotations.tab.information.title',
            rules: {
              hide: [
                {
                  property: 'quotation_id',
                  op: 'eq',
                  value: null,
                },
              ],
            },
            sections: [
              {
                key: 'entity',
                title: 'resource.quotations.section.information.title',
                layout: [
                  ['quotation.begin_date', 'quotation.end_date'],
                  ['quotation.kind'],
                  ['quotation.vat', 'quotation.admin_vat'],
                  ['quotation.total_duty', 'quotation.admin_total_duty'],
                  ['quotation.total_ttc', 'quotation.admin_total_ttc'],
                  [
                    'quotation.total_duty_with_charges',
                    'quotation.admin_total_duty_with_charges',
                  ],
                  [
                    'quotation.total_ttc_with_charges',
                    'quotation.admin_total_ttc_with_charges',
                  ],
                ],
                inputs: {
                  'quotation.begin_date': {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  'quotation.end_date': {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  'quotation.kind': {
                    validators: [{ key: 'required' }],
                    disabled: true,
                  },
                  'quotation.vat': {
                    width: 6,
                  },
                  'quotation.admin_vat': {
                    width: 6,
                  },
                  'quotation.total_duty': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  'quotation.admin_total_duty': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  'quotation.total_ttc': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  'quotation.admin_total_ttc': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  'quotation.total_duty_with_charges': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  'quotation.admin_total_duty_with_charges': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  'quotation.total_ttc_with_charges': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  'quotation.admin_total_ttc_with_charges': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'quotation.kind',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'entity',
                title:
                  'resource.quotations.section.information.technical_assistance.title',
                layout: [
                  ['quotation.quantity'],
                  ['quotation.cost_per_unit', 'quotation.admin_cost_per_unit'],
                  ['quotation.unit'],
                ],
                rules: {
                  hide: [
                    {
                      property: 'quotation.kind',
                      op: 'eq',
                      value: 'forfeit',
                    },
                  ],
                },
                inputs: {
                  'quotation.quantity': {
                    validators: [
                      { key: 'required' },
                      {
                        key: 'minValue',
                        value: 1,
                      },
                      {
                        key: 'maxValue',
                        value: 5,
                      },
                    ],
                    min: 1,
                    max: 5,
                    step: 1,
                  },
                  'quotation.cost_per_unit': {
                    width: 6,
                  },
                  'quotation.admin_cost_per_unit': {
                    width: 6,
                  },
                  'quotation.unit': {
                    validators: [{ key: 'required' }],
                  },
                },
              },
              {
                key: 'charges',
                title: 'resource.quotations.section.information.forfeit.title',
                rules: {
                  hide: [
                    {
                      property: 'quotation.kind',
                      op: 'eq',
                      value: 'technical_assistance',
                    },
                    {
                      property: 'quotation_without_charges',
                      op: 'eq',
                      value: true,
                    },
                  ],
                },
                layout: [],
                inputs: {},
                list: {
                  resource_to_fetch: 'charges',
                  hasCreateButton: false,
                  filter_resource: 'proposal',
                  fields: [
                    {
                      source: 'title',
                      label: 'resource.charges.field.title.label',
                      type: 'text',
                    },
                    {
                      source: 'unit',
                      label: 'resource.charges.field.unit.label',
                      type: 'select',
                    },
                    {
                      source: 'quantity',
                      label: 'resource.charges.field.quantity.label',
                      type: 'number',
                    },
                    {
                      source: 'admin_quantity',
                      label: 'resource.charges.field.admin_quantity.label',
                      type: 'number',
                    },
                    {
                      source: 'cost_per_unit',
                      label: 'resource.charges.field.cost_per_unit.label',
                      type: 'number',
                    },
                    {
                      source: 'admin_cost_per_unit',
                      label: 'resource.charges.field.admin_cost_per_unit.label',
                      type: 'number',
                    },
                  ],
                },
              },
            ],
          },
          {
            key: 'milestones',
            title: 'resource.milestones.tab.information.title',
            rules: {
              hide: [
                {
                  property: 'quotation_id',
                  op: 'eq',
                  value: null,
                },
              ],
            },
            sections: [
              {
                key: 'milestones',
                title:
                  'resource.proposals.section.information.milestones.title',
                layout: [],
                inputs: {},
                list: {
                  actions: [
                    {
                      name: 'redirect_to_milestone_creation',
                      type: 'redirect',
                      label:
                        'resource.users.bulk_action_buttons.create_a_milestone.label',
                      extraUrl: '/milestones/create',
                      hiddenInput: [
                        {
                          key: 'kind',
                          value: 'quotation_kind',
                        },
                        {
                          key: 'quotation_id',
                          value: 'quotation_id',
                        },
                        {
                          key: 'proposal_id',
                          value: 'id',
                        },
                      ],
                    },
                  ],
                  resource_to_fetch: 'milestones',
                  filter_resource: 'proposal',
                  fields: [
                    {
                      source: 'title',
                    },
                    {
                      source: 'begin_date',
                    },
                    {
                      source: 'end_date',
                    },
                    {
                      source: 'units_worked',
                      rules: {
                        hide: [
                          {
                            property: 'quotation.kind',
                            op: 'neq',
                            value: 'technical_assistance',
                          },
                        ],
                      },
                    },
                    {
                      source: 'admin_units_worked',
                      rules: {
                        hide: [
                          {
                            property: 'quotation.kind',
                            op: 'neq',
                            value: 'technical_assistance',
                          },
                        ],
                      },
                    },
                    {
                      source: 'total_duty',
                    },
                    {
                      source: 'admin_total_duty',
                    },
                    {
                      source: 'total_charges',
                      rules: {
                        hide: [
                          {
                            property: 'quotation.kind',
                            op: 'neq',
                            value: 'technical_assistance',
                          },
                        ],
                      },
                    },
                    {
                      source: 'admin_total_charges',
                      rules: {
                        hide: [
                          {
                            property: 'quotation.kind',
                            op: 'neq',
                            value: 'technical_assistance',
                          },
                        ],
                      },
                    },
                    {
                      source: 'total_duty_with_charges',
                      rules: {
                        hide: [
                          {
                            property: 'quotation.kind',
                            op: 'neq',
                            value: 'technical_assistance',
                          },
                        ],
                      },
                    },
                    {
                      source: 'admin_total_duty_with_charges',
                      rules: {
                        hide: [
                          {
                            property: 'quotation.kind',
                            op: 'neq',
                            value: 'technical_assistance',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.proposals.section.details.title',
              layout: [
                ['id'],
                ['guid'],
                ['status'],
                ['created_at', 'updated_at'],
                ['accepted_at'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                guid: {
                  disabled: true,
                },
                status: {
                  rules: {
                    disabled: [
                      {
                        property: 'status',
                        op: 'not_includes',
                        value: [
                          'draft',
                          'review_admin',
                          'review_client',
                          'request_for_update',
                          'refused',
                          'archived',
                        ],
                      },
                    ],
                  },
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                accepted_at: {},
              },
              fields: {},
            },
          ],
        },
        {
          sections: [
            {
              key: 'link',
              title: 'resource.proposals.section.links.title',
              layout: [
                ['candidacy_owner_id'],
                ['company_name'],
                ['candidacy_id'],
                ['company_legal_information_id'],
                ['mission_owner_id'],
                ['mission_company_name'],
                ['mission_id'],
              ],
              inputs: {},
              fields: {
                candidacy_owner_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                company_name: {
                  source: 'candidacy_owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.users.field.company_name_profile.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
                candidacy_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'candidacy.name',
                  },
                },
                company_legal_information_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'company_legal_information.id',
                  },
                },
                mission_owner_id: {
                  addLabel: true,
                  label: 'resource.candidacies.field.mission_owner_id.label',
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                mission_company_name: {
                  source: 'mission_owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.missions.field.company_id.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
                mission_id: {
                  addLabel: true,
                  source: 'mission_id',
                  label: 'resource.candidacies.field.mission_id.label',
                  child: {
                    type: 'text',
                    source: 'mission.name',
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
